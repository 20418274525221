import BaseAction from '@/actions/base_action.js'
import SnapshotsCreate from '@/actions/snapshots/create.js'

export default class extends BaseAction {
  execute(spec, component) {
    const t = TrelloPowerUp.iframe()

    const promise = $tpu.r.projects.create(spec.properties, t).then(([project, scope]) => {
      const snapshotItems = [
        {
          x_axis: 0,
          title: scope.name,
          scope_card_uuid: scope.id
        }
      ]
      return SnapshotsCreate.promise('Project started', snapshotItems, project.id, component)
    }).then(() => {
      const elementId = "project_name"
      GLib.action.execute({
        "action": "fields/reset",
        "targetId": elementId,
        "onReset": {
          "action": "fields/focus",
          "targetId": elementId,
        }
      }, component)
    })

    this.executePromise(promise, spec, component, false)
  }
}
